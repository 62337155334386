import request, { setToken } from "@/tools/request";
import { setCookie } from "@/tools/cookie";
import { proxyPrefix } from "./config";

export const queryVerificationCode = (params) => {
  return request.post(
    `${proxyPrefix}/api/wx/userCenter/beyond/queryVerificationCode`,
    params
  );
};

export const queryPhoneRegister = (params) => {
  return request.post(
    `${proxyPrefix}/api/wx/userCenter/userInfo/isPhoneRegister`,
    params
  );
};

export const userLogin = (params) => {
  return request
    .post(`${proxyPrefix}/api/wx/userCenter/beyond/login`, params)
    .then((res) => {
      if (res?.code === 0) {
        const data = res?.data;
        let tokenStr = "";
        Object.keys(data).forEach((key) => {
          if (data[key] !== null) {
            setCookie(key, data[key]);

            if (tokenStr === "") {
              tokenStr += `${key}=${data[key]}`;
            } else {
              tokenStr += `;${key}=${data[key]}`;
            }
          }
        });

        if (tokenStr) {
          setToken(tokenStr);
        }
      }
      return res;
    });
};

export const userLoginByPassword = (params) => {
  return request
    .post(`${proxyPrefix}/api/wx/userCenter/beyond/login/auth`, params)
    .then((res) => {
      if (res?.code === 0) {
        const data = res?.data;
        let tokenStr = "";
        Object.keys(data).forEach((key) => {
          if (data[key] !== null) {
            setCookie(key, data[key]);

            if (tokenStr === "") {
              tokenStr += `${key}=${data[key]}`;
            } else {
              tokenStr += `;${key}=${data[key]}`;
            }
          }
        });

        if (tokenStr) {
          setToken(tokenStr);
        }
      }
      return res;
    });
};

export const queryCityList = () => {
  return request
    .get(`${proxyPrefix}/api/wx/userCenter/cityList`)
    .then((res) => {
      const provinceMap = new Map();
      const data = res.data;
      data.map((location) => {
        if (location.parentId === "10000000") {
          provinceMap.set(location.id, {
            value: location.id,
            text: location.name,
            children: [],
          });
        }
      });
      data.map((location) => {
        if (provinceMap.has(location.parentId)) {
          const province = provinceMap.get(location.parentId);
          province.children.push({
            text: location.name,
            value: location.id,
          });
        }
      });
      return [...provinceMap.values()].sort((a, b) => {
        return a.value - b.value;
      });
    });
};

export const queryByToken = () => {
  return request.get(`${proxyPrefix}/api/wx/userCenter/userInfo/queryByToken`);
};
