/*
 * @Author: tongteng
 * @Date: 2023-03-13 15:34:41
 * @LastEditors: tongteng
 * @LastEditTime: 2023-06-25 10:19:02
 * @Description: 请填写简介
 */
import request from '@/tools/request'
const CHANNEL = process.env.CHANNEL
const API_MODE = process.env.API_MODE
const hostMap = {
    'A': 'https://www.wxhfrj.com',
    'B': 'https://www.kldswz.com',
    'C': 'https://www.danvrena.com',
    'D': 'https://www.rtwsq.com'
}
/**  D链路备选：使用ip直接调用接口 */
const ipHostMap = {
    'B': 'https://47.111.87.170',
    'C': 'https://47.111.87.170',
    'D': 'https://47.111.87.170'
}

export const proxyHost = API_MODE === 'IP' ? ipHostMap[CHANNEL] : hostMap[CHANNEL]


// 获取阿里云oss秘钥
export const getOssSecret = params => {
    return request.post(`${proxyHost}/storage/token`, params)
}
// 提交
export const submitSourceUrl = params => {
    return request.post(`${proxyHost}/resource/add`, params)
}

/**
 * 任务文件批量提交: https://yapi.sankuai.com/thrift/project/16577/interface/api/2957619
 */
export const submitSourceAll = params => {
    return request.post(`${ proxyHost }/resource/addAll`, params);
}

// 埋点
export const addBuriedPoint = params => {
    return request.post(`${proxyHost}/buriedPoint/add`, params)
}
// 获取服务端时间
export const getSystemTime = () => {
    return request.get(`${proxyHost}/system/time`)
}