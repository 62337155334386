import Cookies from 'js-cookie'

const DEFAULT_OPTIONS = { expires: 7, sameSite: 'None', secure: true }

export function setCookie(key, value, options) {
  const assignOptions = Object.assign(DEFAULT_OPTIONS, options)
  Cookies.set(key, value, assignOptions)
}

export function getCookie(key) {
  return Cookies.get(key)
}

export function removeCookie(key, options) {
  Cookies.remove(key, options)
}
