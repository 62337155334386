import request from "@/tools/request";
import { proxyPrefix } from "./config";

const commonPath = "api/wx";

export const taskSubmit = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/new`, params);
};

export const passportSubmitTask = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/passport/new`, params);
};

export const getJobDetails = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/job/detailNoAdmin`, params);
};

export const getCity = (params) => {
  return request.get(`${proxyPrefix}/${commonPath}/common/cityInfo`, params);
};
export const getTaskDetails = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/detail`, params);
};
export const getUserTaskData = (params) => {
  return request.get(
    `${proxyPrefix}/${commonPath}/users/tasks/queryUserContributionStatsData`,
    params
  );
};

export const getPassportUserTaskData = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/users/tasks/passport/queryUserContributionStatsData`,
    params
  );
};

export const getUserAccessValid = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/common/userAccessValid`,
    params
  );
};

export const getFileUrl = (params) => {
  return request
    .post(
      `/${
        window.eraState.elk ? "elk" : "matrix"
      }/febffapi/uploadapi/getFileUrl`,
      params
    )
    .then((res) => {
      const url = res?.data?.url;
      if (url && !url.startsWith("http")) {
        res.data.url = `${location.protocol}//${url}`;
      }
      return res;
    });
};

export const judgeWhetherUploadVideo = () => {
  return request.get(
    `${proxyPrefix}/${commonPath}/task/judgeWhetherUploadVideo`
  );
};

export const judgeWhetherUploadVideoPoi = () => {
  return request.get(
    `${proxyPrefix}/${commonPath}/task/judgeWhetherUploadVideoPoi`
  );
};

export const judgeWhetherUploadVideoPoiRuiTu = () => {
  return request.get(
    `${proxyPrefix}/${commonPath}/task/judgeWhetherUploadVideoPoiRuiTu`
  );
};

export const listSkuCategoriesPreviousDay = (params) => {
  return request.get(
    `${proxyPrefix}/${commonPath}/skuCategory/listSkuCategoriesPreviousDay`,
    { params }
  );
};

export const insertSkuCategoryTask = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/skuCategory/insertSkuCategoryTask`,
    params
  );
};

export const getListSkuCategoriesCurrentDay = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/skuCount/listSkuCategoriesCurrentDay`,
    params
  );
};

export const executeSkuCountTask = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/skuCount/executeSkuCountTask`,
    params
  );
};

export const getListCompetitorCategories = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/skuReviewCount/listCompetitorCategories`,
    params
  );
};

export const executeSkuCountReviewTask = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/skuReviewCount/executeSkuCountReviewTask`,
    params
  );
};

export const getMccConfig = () => {
  return request.get(
    `${proxyPrefix}/${commonPath}/riskControlling/type/listMccConfig`
  );
};

export const submitFeedback = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/riskControlling/submit/riskControllingUsers`,
    params
  );
};

export const getUserTaskList = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/wxUser/userTask/list`,
    params
  );
};

export const getUserTaskStatusCount = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/wxUser/userTask/countByStatus`,
    params
  );
};

export const getCollectDataList = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/task/preSubmitDataList`,
    params
  );
};

export const checkApprove = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/task/preSubmitData/checkApprove`,
    params
  );
};

export const checkDelete = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/task/preSubmitData/checkDelete`,
    params
  );
};

export const querySubmitDataPicNum = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/task/preSubmitData/querySubmitDataPicNum`,
    params
  );
};

export const verifyQuery = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/onlineCollectData/layoutQuery`,
    params
  );
};

export const userTaskHeadImage = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/wxUser/userTask/headImage`,
    params
  );
};

export const reportBuriedPoint = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/buriedPoint/report`,
    params
  );
};

export const getRiskTypesList = (params) => {
  return request.get(
    `${proxyPrefix}/${commonPath}/riskControlling/type/riskTypes`,
    { params }
  );
};

export const getUserTaskValidStatus = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/task/submit/status`,
    params
  );
};

export const deleteAllData = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/delete/all`, params);
};

export const approveQuality = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/pass/all`, params);
};

// 获取sku apk采集初始资源文件
export const getApkResource = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/apk/resource`, params);
};

// 任务列表页确认点击复制
export const confirmAllocation = (params) => {
  return request.post(
    `${proxyPrefix}/${commonPath}/wxUser/userTask/allocation/confirm`,
    params
  );
};

// 更新Kl任务类型的任务状态
export const updateKlResearchStatus = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/complete`, params);
};
// 提交excel配置
export const excelSubmit = `${proxyPrefix}/${commonPath}/manage/data/submit`;
// 查询提交的excel配置历史记录
export const queryHistorySubmit = (params) => {
  return request.get(
    `${proxyPrefix}/${commonPath}/manage/data/queryHistory`,
    { params }
  );
};

/** apk采集进度明细下载
 * https://yapi.sankuai.com/thrift/project/16577/interface/api/2792926
 */
export const downloadApkExcel = () => {
  return request.get(`${proxyPrefix}/${commonPath}/wxUser/data/download`);
};

/** 众包领取任务接口
 * https://yapi.sankuai.com/thrift/project/16577/interface/api/2936459
 */
export const getCrowdSourceTask = () => {
  return request.post(`${proxyPrefix}/${commonPath}/task/acquire`);
};

/** 众包放弃任务接口
 * https://yapi.sankuai.com/thrift/project/16577/interface/api/2936773
 */
export const abandonCrowdSourceTask = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/abandon`,params);
};

/** 众包任务提交前校验接口
 * https://yapi.sankuai.com/thrift/project/16577/interface/api/2936345
 */
export const submitCheckCrowdSource = (params) => {
  return request.post(`${proxyPrefix}/${commonPath}/task/submitCheck`,params);
};

/**
 * 获取可用厂商及版本列表
 * https://yapi.sankuai.com/thrift/project/16577/interface/api/2963677
 */
export const requestBrands = () => {
  return request.get(`${proxyPrefix}/${commonPath}/app/brands`);
}

/**
 * 获取应用信息
 * https://yapi.sankuai.com/thrift/project/16577/interface/api/2963720
 */
export const  getAppInfo = params => {
  return request.post(`${proxyPrefix}/${commonPath}/app/info`, params);
}
