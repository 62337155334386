
import { getTokenToObj } from '@/tools/utils'

/*
 * @Author: tongteng
 * @Date: 2023-03-13 15:34:41
 * @LastEditors: tongteng
 * @LastEditTime: 2023-03-17 11:22:04
 * @Description: 请填写简介
 */
const AWS_PATH = process.env.AWS_PATH || ''
const CHANNEL = process.env.CHANNEL || ''
const NGINX_BUILD = process.env.NGINX_BUILD || 'aws'
export const isTestEnv = AWS_PATH.includes('test');
const hostMap = {
  'B': {
    'aws': 'https://kaleidoscope-pre.myazsjhf.com',
    'nginx': 'https://front-resource.kldswz.com'
  },
  'C': {
    'aws': 'https://kaleidoscope-pre.huashk.com',
    'nginx': 'https://front-resource.danvrena.com'
  },
  'D': {
    'aws': 'https://kaleidoscope-pre.fpgept.com',
    'nginx': 'https://front-resource.rtwsq.com'
  }
}
export const proxyPrefix = `${hostMap[CHANNEL][NGINX_BUILD]}${isTestEnv?'/test':''}`


// 任务列表页链接
export const DEFAULT_CALLBACK_URL = `/elk/taskList`;
// 用户注册上传页链接
export const USER_REGISTER_URL = '/elk/userRegister';
// 调度配置上传页链接
export const TASK_ALLOCATE_URL = '/elk/taskAllocate';
// 众包任务列表页链接
export const CROWD_SOURCE_URL = `/elk/crowdSourceTaskList`;

// 登陆type类型
export const LoginTypeEnum = {
  TASK_COLLECT: 1, // 任务采集
  USER_REGISTER_ASSIGN: 2, // 注册人员配置
  TASK_ALLOCATE_ASSIGN: 3, // 任务调度配置
}



// 配置策略类型
export const allocationStrategyMap = {
  CROWD_SOURCE: 1, // 众包（自主领取）
  SYSTEM_DISTRIBUTION: 2 // 非众包（系统下发）
}

// 跳转链接枚举
const { allocationStrategy = allocationStrategyMap.SYSTEM_DISTRIBUTION } = getTokenToObj() || {};

const listRedirectByallocationStrategy = {
  1: CROWD_SOURCE_URL,
  2: DEFAULT_CALLBACK_URL
}

export const redirectUrlMap = {
  [LoginTypeEnum.TASK_COLLECT]: listRedirectByallocationStrategy[allocationStrategy] || DEFAULT_CALLBACK_URL,
  [LoginTypeEnum.USER_REGISTER_ASSIGN]: USER_REGISTER_URL,
  [LoginTypeEnum.TASK_ALLOCATE_ASSIGN]: TASK_ALLOCATE_URL
}


export const redirectUrlByAllocationStrategy = (toPath) => {
  const { allocationStrategy = allocationStrategyMap.SYSTEM_DISTRIBUTION } = getTokenToObj() || {};
  // 众包跳非众包列表页时，改成众包列表页
  if(('/elk' + toPath) === DEFAULT_CALLBACK_URL && +allocationStrategy === allocationStrategyMap.CROWD_SOURCE){
    location.replace(CROWD_SOURCE_URL)
  }
  // 非众包跳众包列表页时，要改成非众包列表页
  else if(('/elk' + toPath) === CROWD_SOURCE_URL && +allocationStrategy === allocationStrategyMap.SYSTEM_DISTRIBUTION){
    location.replace(DEFAULT_CALLBACK_URL)
  }
}

export const roleTypeMap = {
  COMMON: 1, // 普通用户（包括众包非众包）
  AGENT: 2, // 代理
  SUPPER: 3 // 超管
}

// 这些路径不用登录就可以访问
export const loginWhitePath = [
  '/skuTask',
  '/poiTaskDistribution',
  '/categoryTask',
  '/skuCountTask',
  '/skuCountReviewTask',
  '/skuSearchTask',
  '/activityTask',
  '/supplierLeadsTask',
  '/skuApkTask',
  '/klResearchTask',
  '/hawkEyeTask'
]
